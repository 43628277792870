<template>
  <section>
    <p>Статьи приходов</p>
    <category
      v-for="(category, index, key) in categories"
      :key="key"
      :name="category.name"
      :items="category.elements"
      :id="category.id"
      @add-element="addElement"
      @remove-element="removeElement"
    ></category>
    <button class="add_more_btn" @click="addCategory">
      + добавить категорию
    </button>
  </section>
</template>

<script>
import Category from "./parts/ExpenditureCategory";

async function sendRequest(fileName, data) {
  const response = await fetch(`/php/${fileName}.php`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=utf-8",
    },
    body: JSON.stringify(data),
  });

  let answerData = await response.json();

  if (answerData.errors) {
    alert("Произошла ошибка, см. консоль");
    console.log("Ошибка: " + answerData.message);
    return false;
  }
  return answerData;
}

export default {
  components: {
    category: Category,
  },
  data() {
    return {
    };
  },
  computed: {
    categories() {
      return this.$store.getters['getComesCategories'];
    } 
  },
  methods: {
    async addCategory() {

      // Получаем имя категории
      let nameCategory = prompt("Введите название категории");


      // Провека имени
      if (nameCategory == false) {
        return;
      }


      // Отправляем запрос на добавление если возникает ошибка то выходим из функции
      let answer = await sendRequest("comes/addCategory", {
          name: nameCategory,
          isComingCategory: true,
        })
      if (!answer) {
        return;
      }


      // Если все прошло успешно то добавляем категорию в локальном хранилище с пришедшим ID

      let localCategories = this.categories;
      localCategories[answer] = {
        id: answer,
        name: nameCategory,
        elements: [],
      };

      this.$store.commit('setComesCategories', localCategories);
    },
    async addElement(categoryName, categoryId) {
      let nameElement = prompt("Введите название статьи");

      // Провека имени
      if (nameElement == false) {
        return;
      }

      // Отправляем запрос на добавление если возникает ошибка то выходим из функции
      let answer = await sendRequest("comes/addCategoryItem", {
          name: nameElement,
          categoryId,
        })
      if (!answer) {
        return;
      }

      // Если все прошло успешно то добавляем в локальном хранилище с пришедшим ID

      let localCategories = this.categories;
      localCategories[categoryId].elements.push({name: nameElement, id: answer});

      this.$store.commit('setComesCategories', localCategories);
    },
    async removeElement(elementObj) {

      await sendRequest('comes/removeCategoryItem', {
        id: elementObj.id
      });


      // Если все прошло успешно то удаляем в локальном хранилище

      let localCategories = this.categories;
      localCategories[elementObj.categoryId].elements = this.categories[elementObj.categoryId].elements.filter((item) => item.id != elementObj.id);

      this.$store.commit('setComesCategories', localCategories);
    },
  },
};
</script>


<style lang = "scss" scoped>
section > p {
  font-size: 18px;
  text-align: center;
  margin-bottom: 32px;
}
</style>