<template>
  <ul class="category" :class = "{'is-active': listIsShown}">
    <span @click = "toggleList">{{ name }}</span>
    <li
      class="category_item"
      v-for="(item, index) in items"
      :key="item + index + id"
      @click = "removeElement(item.name, item.id)"
    >
      {{ item.name }}
    </li>
    <button class="add_more_btn" v-if = "listIsShown" @click="addElement">+ добавить элемент</button>
  </ul>
</template>

<script>
export default {
  props: ["name", "items", "id"],
  emits: ["add-element", "remove-element"],
  data() {
    return {
      listIsShown: false,
    };
  },
  methods: {
    addElement() {
      this.$emit("add-element", this.name, this.id);
    },
    toggleList() {
      this.listIsShown = !this.listIsShown;
    },
    removeElement(item,id) {
      if(confirm(`Вы уверены что хотите удалить элемент: ${item}?`)) {
        this.$emit("remove-element", {
          id,
          category: this.name,
          categoryId: this.id,
          itemName: item.name,
        });
      }
    }
  },
};
</script>

<style lang = "scss" scoped>
ul {
  margin: 0;
  padding: 0;
  margin-bottom: 40px;
  list-style: none;
  transition: all .3s ease-in-out;


  > span {
    display: flex;
    align-items: center;
    font-size: 18px;
    margin-block: 8px;
    cursor: pointer;

    &:before {
      display: block;
      content: "";
      width: 12px;
      height: 12px;
      min-width: 12px;
      margin-right: 8px;
      background-image: url(~@/assets/img/icons/right-arr.svg);
      background-repeat: no-repeat;
      background-size: contain;
      transition: transform 0.15s ease-in-out;
    }
  }

  li {
    padding-left: 32px;
    color: #75B5D6;
    display: none;
    transition: all 0.3s ease-in-out;
    position: relative;
    margin-bottom: 6px;
    padding-top: 6px;
    padding-bottom: 6px;
    cursor: pointer;
    &:before {
      display: block;
      content: "";
      width: 20px;
      max-height: 20px;
      height: 100%;
      background-image: url(~@/assets/img/icons/trash.svg);
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      top: 4px;
      left: 8px;
      opacity: 0;
      transition: 0.3s opacity ease-in-out;
    }

    &:hover {
      text-decoration: underline dashed;
      &:before {
        opacity: 1;
      }
    }

    &:last-of-type {
      margin-bottom: 16px;
    }
  }

  button {
    max-width: calc(100% - 64px);
    display: block;
    margin-left: auto;
    margin-right: auto;
  }


  &.is-active {
    span {
      &:before {
        transform: rotateZ(90deg);
      }
    }
    li {
      display: block;
      height: auto;
    }
  }
}
</style>